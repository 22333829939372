<template>
  <section>
    <section class="nav-container m-t-18">
      <a
        @click="changeNav(item.key)"
        :class="item.key == navActiveKey ? 'active' : ''"
        v-for="item in navMap"
        :key="item.key"
        >{{ item.name }}</a
      >
    </section>
    <section class="search-container">
      <a-form layout="inline" class="m-t-14">
        <a-form-item label="所属单位">
          <a-select
            showSearch
            option-filter-prop="children"
            class="search-select"
            placeholder="全部"
            v-model="searchInfo.companyId"
          >
            <a-select-option value>全部</a-select-option>
            <a-select-option
              v-for="item in companyMap"
              :key="item.id"
              :value="item.id"
              >{{ item.companyName }}</a-select-option
            >
          </a-select>
        </a-form-item>
        <!-- 单位类型 -->
        <a-form-item label="单位类型" v-if="navActiveKey == 2">
          <a-select
            showSearch
            option-filter-prop="children"
            class="search-select"
            placeholder="全部"
            v-model="searchInfo.companyTypeId"
          >
            <a-select-option value>全部</a-select-option>
            <a-select-option
              v-for="item in companyTypeMap"
              :key="item.id"
              :value="item.id"
              >{{ item.companyTypeName }}</a-select-option
            >
          </a-select>
        </a-form-item>
        <a-form-item v-if="navActiveKey == 1" label="职务">
          <a-select
            showSearch
            option-filter-prop="children"
            class="search-select"
            placeholder="全部"
            v-model="searchInfo.jobId"
          >
            <a-select-option value>全部</a-select-option>
            <a-select-option
              v-for="item in jobsMap"
              :key="item.id"
              :value="item.id"
              >{{ item.name }}</a-select-option
            >
          </a-select>
        </a-form-item>
        <a-form-item label="人员姓名">
          <a-input
            class="search-input"
            placeholder="请输入"
            v-model="searchInfo.personnelName"
          />
        </a-form-item>
        <a-form-item>
          <a-input-group compact>
            <a-select
              style="width: 100px; margin-right: 2px"
              v-model="searchInfo.type"
            >
              <a-select-option :value="1">登记时间</a-select-option>
              <a-select-option :value="2">退场时间</a-select-option>
            </a-select>
            <a-range-picker
              class="search-range-date"
              v-model="searchInfo.endDate"
            />
          </a-input-group>
          <!-- <a-input class="search-input" placeholder="请输入" /> -->
        </a-form-item>
        <a-form-item>
          <a-button type="primary" class="m-r-8" @click="search">
            <a-icon type="search" />查询
          </a-button>
          <a-button @click="reset"> <a-icon type="reload" />重置 </a-button>
          <a-button
            class="m-r-8"
            v-if="isShowButton"
            style="margin-left: 5px"
            @click="$router.back()"
            >返回危险源</a-button
          >
        </a-form-item>
      </a-form>
      <a-row class="right-action-panel m-t-14">
        <!-- <a-dropdown class="item">
                    <a-button>批量操作<a-icon type="caret-down" /></a-button>
                    <a-menu slot="overlay">
                        <a-menu-item key="1"> 模板下载 </a-menu-item>
                        <a-menu-item key="2"> 模板导入 </a-menu-item>
                        <a-menu-item key="3" @click="exprotFile('exportCarLogList', {params: searchInfo})"> 信息导出 </a-menu-item>
                    </a-menu>
        </a-dropdown> -->
        <a-select
          class="item"
          style="width: 120px"
          type="primary"
          placeholder="批量操作"
        >
          <a-select-option value="模板下载" @click="exportModel"
            >模板下载</a-select-option
          >
          <a-select-option value="信息导入" @click="uploadModal = true"
            >信息导入</a-select-option
          >
          <a-select-option
            value="信息导出"
            @click="exprotFile('exportPersonnel', { params: searchInfo })"
            >信息导出</a-select-option
          >
        </a-select>
        <a-button class="item" @click="personnelWalkOff">批量退场</a-button>
        <router-link
          class="item"
          :to="{
            path: '/pcms/labour/person/add',
            query: { personnelType: navActiveKey },
          }"
        >
          <a-button type="primary">添加人员</a-button>
        </router-link>
        <a-button class="item" @click="multipleIssued">批量下发</a-button>
        <a-checkbox
          class="item m-t-6"
          v-model="allCheckStatus"
          @change="(e) => allCheck(e.target.checked)"
          >全选</a-checkbox
        >
        <a-checkbox-group
          class="item m-t-6"
          v-model="personnelStatusFilterMap"
          @change="queryPersonnelList"
        >
          <a-checkbox value="isAbnormal">显示异常人员</a-checkbox>
          <a-checkbox value="isEntrance">显示离场人员</a-checkbox>
          <a-checkbox value="liveBuiltBureau">显示标记人员</a-checkbox>
        </a-checkbox-group>
      </a-row>
    </section>
    <a-row
      v-if="listMap.length"
      type="flex"
      :gutter="20"
      class="list-card-container"
    >
      <a-col :span="6" v-for="(item, key) in listMap" :key="key" class="m-t-16">
        <!-- <a-row class="list-card-bg"> -->
        <a-row class>
          <a-row type="flex" class="list-wrap">
            <a-checkbox
              v-if="item.isEntrance == 1"
              v-model="item.checked"
              class="list-checkBox"
            ></a-checkbox>
            <a-col style="width: 100px" class="list-image">
              <a-row v-if="item.isAbnormal == 2" class="list-image-state"
                >异常数据</a-row
              >
              <img style="width: 90px; height: 120px" :src="item.avatar" alt />
            </a-col>
            <a-col style="width: calc(100% - 100px)">
              <a-row class="list-item list-title">
                <span>{{ item.personnelName }}</span>
                <span style="margin-left: 4px" v-if="item.jobName"
                  >({{ item.jobName }})</span
                >
              </a-row>
              <a-row class="list-item">
                <span class="key">所属单位：</span>
                <span class="val">{{ item.companyName }}</span>
              </a-row>
              <a-row class="list-item">
                <span class="key">单位类型：</span>
                <span class="val">{{ item.companyType }}</span>
              </a-row>
              <a-row class="list-item">
                <span class="key">联系电话：</span>
                <span class="val">{{ item.phoneNumber }}</span>
              </a-row>
              <a-row class="list-item">
                <span class="key">登记时间：</span>
                <span class="val">{{ item.inDate }}</span>
              </a-row>
              <a-row class="list-item" v-if="item.isEntrance == 1">
                <span class="key">下发设备：</span>
                <span class="val">{{ item.equipmentStatistics }}</span>
              </a-row>
              <a-row class="list-item" v-else>
                <span class="key">下发设备：</span>
                <span class="val"></span>
              </a-row>
              <a-row class="list-btn-group">
                <router-link
                  :to="{
                    path: '/pcms/labour/person/details',
                    query: { id: item.id },
                  }"
                >
                  <a-button size="small">查看</a-button>
                </router-link>
                <a-dropdown>
                  <a-button size="small">
                    更多
                    <a-icon type="caret-down" />
                  </a-button>
                  <a-menu slot="overlay">
                    <a-menu-item key="1" v-if="item.isEntrance == 1">
                      <router-link
                        :to="{
                          path: '/pcms/labour/person/edit',
                          query: { id: item.id, personnelType: navActiveKey },
                        }"
                        >编辑信息</router-link
                      >
                    </a-menu-item>
                    <a-menu-item key="2" v-if="item.isEntrance == 1">
                      <router-link
                        :to="{
                          path: '/pcms/labour/person/leave',
                          query: { id: item.id },
                        }"
                        >人员退场</router-link
                      >
                    </a-menu-item>
                    <a-menu-item key="3" v-if="item.isEntrance == 2">
                      <router-link
                        :to="{
                          path: '/pcms/labour/person/entrance',
                          query: { id: item.id, actionType: 3 },
                        }"
                        >人员再入场</router-link
                      >
                    </a-menu-item>
                    <a-menu-item key="4" v-if="item.isEntrance == 1">
                      <a @click="showDeviceModal(item.id)">下发设备</a>
                    </a-menu-item>
                    <a-menu-item key="5">
                      <router-link
                        :to="{
                          path: '/pcms/labour/person/leaveRecord',
                          query: { id: item.id },
                        }"
                        >离场记录</router-link
                      >
                    </a-menu-item>
                    <a-menu-item key="6" v-if="item.isEntrance == 1">
                      <a-popconfirm
                        title="确定删除该人员信息?"
                        @confirm="deletePerson(item)"
                        >删除信息</a-popconfirm
                      >
                    </a-menu-item>
                  </a-menu>
                </a-dropdown>
              </a-row>
            </a-col>
          </a-row>
          <a-row
            :class="
              item.isEntrance == 1
                ? 'list-status list-status-ok'
                : 'list-status list-status-err'
            "
            >{{ item.isEntrance == 1 ? "已登记" : "已退场" }}</a-row
          >
        </a-row>
      </a-col>
    </a-row>
    <empty v-else :height="330" />
    <!-- 分页 -->
    <section class="pagination-container">
      <a-pagination
        :pageSize="pageSize"
        size="default"
        :current="pageNum"
        :total="total"
        :hideOnSinglePage="true"
        :show-total="(total) => `共${total}条`"
        :showQuickJumper="true"
        :showSizeChanger="false"
        @change="changePageNum"
      />
    </section>
    <section
      class="footer-page"
      v-if="
        personnelIssueInfo.issueCompletion &&
        personnelIssueInfo.issueCompletion < 100
      "
    >
      <a-row class="issued-progress">
        正在下发 进度：{{ personnelIssueInfo.issueCompletion || 0 }}%
        <a class="f-right" @click="issueModal.visible = true">查看</a>
        <a title="关闭" class="close-wrap" @click="cancelIssued">
          <a-icon type="close" />
        </a>
      </a-row>
    </section>
    <!-- 批量导入 -->
    <a-modal
      width="800px"
      height="480px"
      class="modal-container"
      v-model="uploadModal"
      title="模板导入"
      :footer="false"
    >
      <!-- 导入操作 -->
      <div v-if="progress === 1" style="height: 300px">
        <!-- <uploadFile :accept="['.xlsx']" @func="uploadFile" /> -->
        <a-upload-dragger
          name="file"
          :beforeUpload="beforeUploadFiles"
          :fileList="fileList"
          :accept="accept.join(',')"
          @change="uploadFile"
        >
          <p class="ant-upload-drag-icon">
            <a-icon type="inbox" />
          </p>
          <p class="ant-upload-text">点击或将文件拖拽到这里上传</p>
          <p class="ant-upload-hint" style="text-align: left; padding: 0 100px">
            <a-row
              >注意：1.使用模板导入功能必须使用系统中下载的人员信息导入模板。</a-row
            >
            <a-row
              >2.导入模板中请管理人员根据模板文件中的实例，添加人员信息，在进行导入操作。</a-row
            >
            <a-row
              >3.导入人员中，人员信息默认标记为否，若有需要批量上传后，手动修改。</a-row
            >
            <a-row>
              4.模板导入的人员信息中，不包含人员近期照片，身份证照片等相关人员附件，后
              续还需要上传人员附件。
            </a-row>
          </p>
        </a-upload-dragger>
      </div>
      <!-- 进度条展示 -->
      <div v-else-if="progress === 2" style="text-align: center">
        <a-progress :percent="percent" />
        <p class="gray" style="margin-top: 20px">
          导入模板中，请勿关闭弹窗，关闭弹窗不会收到导入结果
        </p>
        <p class="gray point">取消导入</p>
      </div>
      <div v-else style="text-align: center">
        <p v-if="isSuccess" style="margin-top: 30px">
          <img src="@/assets/image/icon/success.png" alt />
        </p>
        <p v-else style="margin-top: 30px">
          <img src="@/assets/image/icon/fail.png" alt />
        </p>
        <p
          style="
            font-weight: 600;
            font-size: 22px;
            color: #666666;
            margin-top: 10px;
          "
        >
          {{ isSuccess ? "模板导入成功" : "模板导入失败" }}
        </p>
      </div>
    </a-modal>
    <!-- modal -->
    <a-modal
      class="modal-container"
      centered
      v-model="deviceModal.visible"
      width="880px"
      :title="deviceModal.title"
      :footer="false"
      :destroyOnClose="true"
    >
      <a-row>
        <span class="m-r-10"
          >已选择人数：{{ listMap.filter((item) => item.checked).length }}</span
        >
        <span
          >已选择设备：{{
            deviceLists.filter((item) => item.checked).length
          }}</span
        >
      </a-row>
      <a-row>
        <a-checkbox
          class="item m-t-6"
          v-model="allCheckDeviceStatus"
          @change="(e) => allDeviceCheck(e.target.checked)"
          style="float: right; margin-bottom: 10px"
          >全选</a-checkbox
        >
      </a-row>
      <a-row type="flex" :gutter="20" class="list-card-container">
        <a-col
          :span="8"
          v-for="(item, key) in deviceLists"
          :key="key"
          class="m-t-16"
        >
          <a-row class="list-card-bg">
            <a-row class="list-wrap">
              <div>
                <!-- <a-checkbox v-if="item.deviceState == 1" v-model="item.checked" class="list-checkBox"></a-checkbox> -->
                <a-checkbox
                  v-model="item.checked"
                  class="list-checkBox"
                ></a-checkbox>
                <span class="yangsclas">{{
                  item.type == 0 ? "未下发" : "已下发"
                }}</span>
              </div>
              <a-col>
                <a-row class="list-item">
                  <span class="m-r-8">{{ item.deviceName }}</span>
                  <span
                    :style="{ color: item.deviceState == 1 ? 'green' : 'red' }"
                    >({{ item.deviceState == 1 ? "在线" : "离线" }})</span
                  >
                </a-row>
                <a-row class="list-item">{{
                  item.attendanceType == 1 ? "门禁设备" : "考勤设备"
                }}</a-row>
                <a-row class="list-item">{{ item.remarks }}</a-row>
              </a-col>
            </a-row>
            <!-- <a-tag class="list-status">已完成</a-tag> -->
          </a-row>
        </a-col>
      </a-row>
      <a-row class="form-btn-group m-t-18" style="margin-top: 10px">
        <a-button class="btn-save" @click="personnelIssued">
          <a-icon type="save" />下发设备
        </a-button>
        <a-button class="btn-save" @click="delPersonnelIssued">
          <a-icon type="delete" />删除信息
        </a-button>
        <!-- <a-button class="btn-cancel"  @click="carModal.visible = false">取消</a-button> -->
      </a-row>
    </a-modal>

    <a-modal
      class="modal-container"
      centered
      v-model="issueModal.visible"
      width="880px"
      :title="issueModal.title"
      :footer="false"
      :destroyOnClose="true"
    >
      <section class="info-container">
        <section class="info-item-wrap">
          <p class="info-title">下发进度</p>
          <a-row class="m-b-10 m-t-18">
            <span>下发人数：{{ personnelIssueInfo.issueCount || 0 }}</span>
          </a-row>
          <a-row class="m-b-18">
            <a-progress
              :percent="personnelIssueInfo.issueCompletion || 0"
              status="active"
            />
          </a-row>
        </section>
        <section class="info-item-wrap">
          <p class="info-title">下发明细列表</p>
          <a-row class="m-b-10 m-t-18">
            <span>失败人数：{{ personnelIssueInfo.failureNumber || 0 }}</span>
            <a class="f-right">批量重新下发</a>
          </a-row>
          <section class="list-table-container">
            <a-table
              :columns="issueModal.columns"
              :data-source="personnelIssueInfo.issueLog"
              :pagination="false"
            ></a-table>
          </section>
        </section>
      </section>

      <a-row class="form-btn-group m-t-18">
        <a-button class="btn-cancel" @click="issueModal.visible = false"
          >返回</a-button
        >
      </a-row>
    </a-modal>
  </section>
</template>


<script>
const columns = [
  {
    title: "单位名称",
    dataIndex: "companyName",
    align: "center",
    key: "companyName",
  },
  {
    title: "姓名",
    dataIndex: "personnelName",
    align: "center",
    key: "personnelName",
  },
  { title: "职务/工种", dataIndex: "jobName", align: "center", key: "jobName" },
  {
    title: "失败设备",
    dataIndex: "deviceName",
    align: "center",
    key: "deviceName",
  },
];
import empty from "@/components/empty";
import { exprotFile } from "@/until/unit";
export default {
  data() {
    return {
      id: "",
      isShowButton: false,

      navMap: [
        { name: "管理人员", key: 1 },
        { name: "施工人员", key: 2 },
      ],

      navActiveKey: this.$store.state.pType,
      searchInfo: {
        endDate: [],
        type: 1,
        personnelType:1
      },
      total: 0,
      pageNum: 1,
      pageSize: 8,
      listMap: [], //人员列表
      companyMap: [], //所属单位下拉列表
      companyTypeMap: [], //单位类型下拉列表
      jobsMap: [],
      deviceModal: {
        visible: false,
        title: "下发设备列表",
      },
      issueModal: {
        visible: false,
        title: "下发进度",
        columns,
      },
      deviceLists: [],
      issuedPersonnelId: [],
      personnelIssueInfo: {
        issueLog: [],
      },
      issuedTimer: null,
      issuedGroupId: "",
      personnelStatusFilterMap: [],
      allCheckStatus: false,
      allCheckDeviceStatus: false,

      uploadDisabled: false,
      visible2: false,
      flag: true,
      datas: {},
      msg: "",
      marBottom: "",
      submited: true,

      uploadModal: false, //打开文件上传弹窗
      progress: 1, //步骤
      percent: 0,
      uploadResult: "success", //文件上传状态
      //文件相关
      file: {},
      fileList: [],
      FileArr: [],
      uploadLoadding: false,
      accept: [".xlsx"],
      isSuccess: false,
      selPerList:[],
      baseImage: require('@/assets/image/personnel.png')
    };
  },
  watch: {
    uploadModal: {
      handler(val) {
        if (val == false) this.progress = 1;
      },
    },
  },
  components: { empty },
  created() {
    if (this.$route.query.id) {
      this.isShowButton = true;
      this.id = this.$route.query.id;
    }
    this.init();
    this.queryPersonnelList();
  },
  //   computed: {
  //     id() {
  //       return this.$route.query.id;
  //     }
  //   },
  methods: {
    exprotFile,
    init() {
      this.queryCompanyDropDownBox();
      this.queryTypeDropDownBox();
      this.queryDeviceList();
      this.queryJobsDropDownBox();
    },
    //单位下拉
    queryCompanyDropDownBox() {
      this.$api.queryCompanyDropDownBox().then((res) => {
        if (res.code === 200) {
          this.companyMap = res.data || [];
        }
      });
    },
    //职务工种
    queryJobsDropDownBox() {
      let type = 2;
      this.$api.queryJobsDropDownBox({ type }).then((res) => {
        if (res.code === 200) {
          this.jobsMap = res.data || [];
        }
      });
    },
    //单位类型下拉
    queryTypeDropDownBox() {
      this.$api.queryTypeDropDownBox().then((res) => {
        if (res.code === 200) {
          this.companyTypeMap = res.data || [];
        }
      });
    },

    //下发设备列表
    queryDeviceList() {
      this.$api.queryGateMachineList({ type: 1 }).then((res) => {
        if (res.code === 200) {
          let deviceLists = res.data || [];
          deviceLists.forEach((item) => {
            item.checked = false;
            item.type = 0;
          });
          this.deviceLists = deviceLists;
        }
      });
    },
    changeNav(key) {
      this.navActiveKey = key;
      this.$store.commit("SET_PTYPE", key);
      this.listMap = [];
      this.reset();
    },
    //全选
    allCheck(checked) {
      let listMap = [...this.listMap];
      listMap.forEach((item) => {
        item.checked = checked;
      });
      this.listMap = listMap;
    },
    //设备全选
    allDeviceCheck(checked) {
      let deviceListMap = [...this.deviceLists];
      deviceListMap.forEach((item) => {
        item.checked = checked;
      });
      this.deviceLists = deviceListMap;
    },
    //查询人员列表
    queryPersonnelList() {
      let params = { ...this.searchInfo };
      params.current = this.pageNum;
      params.size = this.pageSize;
      params.liveBuiltBureau = this.personnelStatusFilterMap.includes(
        "liveBuiltBureau"
      )
        ? "1"
        : ""; //显示标记人员
      params.isEntrance = this.personnelStatusFilterMap.includes("isEntrance")
        ? "2"
        : ""; //显示离场人员
      params.isAbnormal = this.personnelStatusFilterMap.includes("isAbnormal")
        ? "2"
        : ""; //显示异常人员
      params.personnelType = this.navActiveKey;
      this.searchInfo.personnelType = this.navActiveKey;
      params.startDate = this.searchInfo.endDate.length
        ? this.searchInfo.endDate[0].format("YYYY-MM-DD")
        : "";
      params.endDate = this.searchInfo.endDate.length
        ? this.searchInfo.endDate[1].format("YYYY-MM-DD")
        : "";
      if (this.id != "") params.hazardId = this.id;
      this.$api.queryPersonnelList(params).then((res) => {
        if (res.code === 200) {
          let listMap = (res.data && res.data.records) || [];
          listMap.forEach((item) => {
            item.checked = false;
            item.avatar = item.avatar
              ? item.avatar.includes("http")
                ? item.avatar
                : this.$store.state.picServer + item.avatar
              : this.baseImage;
          });
          this.listMap = listMap;
          this.total = (res.data && res.data.total) || 0;
        }
      });
    },
    //重置搜索
    reset() {
      this.searchInfo = {
        endDate: [],
        type: 1,
      };
      this.allCheckStatus = false;
      this.pageNum = 1;
      this.queryPersonnelList();
    },
    search() {
      this.pageNum = 1;
      this.allCheckStatus = false;
      this.queryPersonnelList();
    },
    changePageNum(p) {
      this.allCheckStatus = false;
      this.pageNum = p;
      this.queryPersonnelList();
    },
    //删除人员信息
    deletePerson(list) {
      this.$api.deletePersonnel({ id: list.id }).then((res) => {
        if (res.code === 200) {
          this.queryPersonnelList();
          this.unBindFaceDevice(list.id);
        }
      });
    },
    unBindFaceDevice(id) {
      this.$api.unBindFaceDevice(id).then(() => {});
    },
    //批量退场
    personnelWalkOff() {
      this.selPerList = this.listMap
        .filter((item) => item.checked)
        .map((item) => item);
      console.log('选择的退场人员信息=>',this.selPerList);
      localStorage.setItem('selPerInformation', '');
      if (this.selPerList.length) {
        localStorage.setItem('selPerInformation', JSON.stringify(this.selPerList));
         this.$router.push("/pcms/labour/person/batchLeave");
        // this.deviceModal.visible = true;
      } else {
        this.$message.warning("请勾选退场人员");
      }
    },
    //下发设备列表
    showDeviceModal(id) {
      this.deviceModal.visible = true;
      this.issuedPersonnelId = [id];

      this.$api.getFaceDeviceCode(id).then((res) => {
        if (res.code === 200) {
          let deviceLists2 = this.deviceLists;
          for (let i = 0; i < deviceLists2.length; i++) {
            console.log(res.data.includes(deviceLists2[i].deviceCode));
            if (res.data.includes(deviceLists2[i].deviceCode)) {
              deviceLists2[i].type = 1;
            }else{
              deviceLists2[i].type = 0;
            }
          }
          this.deviceLists = deviceLists2;
        }
      });
    },
    //批量下发
    multipleIssued() {
      if (this.issuedGroupId) {
        this.$message.warning("人员正在批量下发中···");
      }
      this.issuedPersonnelId = this.listMap
        .filter((item) => item.checked)
        .map((item) => item.id);
      if (this.issuedPersonnelId.length) {
        this.deviceModal.visible = true;
      } else {
        this.$message.warning("请勾选下发人员");
      }
    },
    //开始下发
    personnelIssued() {
      let deviceCodes = this.deviceLists
        .filter((item) => item.checked)
        .map((item) => item.deviceCode)
        .join(",");
      let personnelIds = this.issuedPersonnelId.join(",");

      this.$api.personnelIssued({ deviceCodes, personnelIds }).then((res) => {
        if (res.code === 200) {
          this.issuedGroupId = res.data || "";
          if (this.issuedGroupId) {
            this.deviceModal.visible = false;
            this.issueModal.visible = true;
            this.tasker();
          }
        }
      });
    },
    delPersonnelIssued(){
      let deviceCodes = this.deviceLists
        .filter((item) => item.checked)
        .map((item) => item.deviceCode)
        .join(",");
      let personnelIds = this.issuedPersonnelId.join(",");

      this.$api.delPersonnelIssued({ deviceCodes, personnelIds }).then((res) => {
        if (res.code === 200) {
          this.issuedGroupId = res.data || "";
          if (this.issuedGroupId) {
            this.deviceModal.visible = false;
            // this.issueModal.visible = true;
            // this.tasker();
            // console.log('res============',res);
            this.queryPersonnelList();
            // this.$forceUpdate();
          }
        }
      });
    },
    //下发结果
    queryPersonnelIssueLog() {
      if (!this.issuedGroupId) return;
      this.$api
        .queryPersonnelIssueLog({ groupId: this.issuedGroupId })
        .then((res) => {
          if (res.code === 200) {
            this.personnelIssueInfo = res.data || {};
            this.personnelIssueInfo.issueLog =
              (res.data && res.data.issueLog) || [];
            if (this.personnelIssueInfo.issueCompletion == 100) {
              this.issueModal.visible = false;
              this.issuedGroupId = "";
              clearInterval(this.issuedTimer);
              this.$message.success("下发完成");
              this.queryPersonnelList();
            }
          }
        });
    },
    cancelIssued() {
      clearInterval(this.issuedTimer);
      this.personnelIssueInfo.issueCompletion = "";
    },
    tasker() {
      const that = this;
      that.issuedTimer = null;
      that.issuedTimer = setInterval(() => {
        that.queryPersonnelIssueLog();
      }, 1000);
    },
    //下载模板
    exportModel() {
      this.exprotFile('exportModel', {})
    },
    beforeUploadFiles(file) {
      this.file = file;
      return false;
    },
    getErrorMessage() {
      let message = "请上传";
      if (this.accept.length === 1) {
        message += this.accept[0].split(".")[1] + "格式文件";
      } else {
        this.accept.forEach((item) => {
          message += item.split(".")[1] + "、";
        });
        message += "等格式文件";
      }
      return message;
    },
    //文件格式判断
    isAcceptFileType() {
      let fileType = this.file.name
        .substring(this.file.name.lastIndexOf("."))
        .toLocaleLowerCase();
      if (!this.accept.includes(fileType)) {
        this.uploadLoadding = false;
        this.$message.warning(this.getErrorMessage());
        return false;
      }
      return true;
    },
    //上传文件到服务器
    uploadFile() {
      this.progress = 2;
      this.percent = 10;
      //文件格式判断
      let fileType = this.file.name
        .substring(this.file.name.lastIndexOf("."))
        .toLocaleLowerCase();
      if (!this.accept.includes(fileType)) {
        this.uploadLoadding = false;
        this.$message.warning(this.getErrorMessage());
        return false;
      }
      this.percent = 20;
      //文件上传对象
      let formData = new FormData();
      formData.append("file", this.file);
      formData.append("personnelType", this.navActiveKey);
      this.uploadLoadding = true;
      this.$api
        .addPersonnelBatch(formData)
        .then((res) => {
          this.percent = 30;
          if (res.code === 200) {
            this.percent = 100;
            this.progress = 3;

            this.isSuccess = true;
            // let file = res.data || {};
            // file.url = this.picServer + file.codeUrl;

            // // 上传单张文件
            // if (!this.uploadUp) {
            //   this.$emit("func", file);
            // } else {
            //   //上传多张文件
            //   if (this.loadDatas) {
            //     //判断删除后的文件再进行上传
            //     this.FileArr = this.loadDatas;
            //   }
            //   this.FileArr.push(file);
            //   //   this.$emit("func", this.FileArr);
            // }
            this.pageNum = 1;
            this.queryPersonnelList();
          } else {
            this.progress = 3;
            this.isSuccess = false;
          }
          this.uploadLoadding = false;
        })
        .catch(() => {
          this.uploadLoadding = false;
        });
    },
  },
};
</script>


<style lang="scss" scoped>
.footer-page {
  position: fixed;
  top: 130px;
  right: 40px;
  width: 360px;
  height: 40px;

  border: 1px solid rgba(92, 152, 236, 0.5);
  z-index: 999;
  border-radius: 4px;
  box-shadow: 1px 1px 10px rgba(0, 0, 236, 0.8);
  .issued-progress {
    height: 100%;
    line-height: 40px;
    padding: 0 16px;
    position: relative;
    .close-wrap {
      position: absolute;
      right: -10px;
      top: -10px;
      display: inline-block;
      color: #ffffff;
      width: 20px;
      height: 20px;
      line-height: 20px;
      text-align: center;
      background: rgba(0, 0, 0, 0.4);
      border-radius: 20px;
      border: 1px solid rgba(255, 255, 255, 0.4);
      cursor: pointer;
      z-index: 9999;
      font-size: 10px;
    }
  }
}
.yangsclas {
  position: absolute;
  top: 10px;
  right: 16px;
}
</style>